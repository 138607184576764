import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/X';

const logoStyle = {
    width: '38px',
    height: '38px',
};

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}
                    sx={{
                        fontFamily: 'Pretendard',
                        fontWeight: '500',
                        fontSize: '15px',
                        letterSpacing: '-1px'
                    }}>
            {'Copyright © '}
            신차빠삭
        </Typography>
    );
}

export default function Footer() {
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: {xs: 4, sm: 8},
                py: {xs: 8, sm: 10},
                textAlign: {sm: 'center', md: 'left'},
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    borderColor: 'divider',
                    fontFamily: 'Pretendard',
                    fontWeight: '500',
                    fontSize: '15px',
                    letterSpacing: '-1px'
                }}
            >
                <div>
                    <Link color="text.secondary" underline='none'>
                        Privacy Policy
                    </Link>
                    <Typography display="inline" sx={{mx: 0.5, opacity: 0.5}}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Link color="text.secondary" underline='none'>
                        Terms of Service
                    </Link>
                    <Copyright/>
                </div>
                <Stack
                    direction="row"
                    justifyContent="left"
                    spacing={1}
                    useFlexGap
                    sx={{
                        color: 'text.secondary',
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="GitHub"
                        sx={{alignSelf: 'center'}}
                    >
                        <FacebookIcon/>
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="X"
                        sx={{alignSelf: 'center'}}
                    >
                        <TwitterIcon/>
                    </IconButton>
                    <Box
                        sx={{mt: 1}}
                    >
                        <Link color="text.secondary" underline='none' href='https://cafe.naver.com/maffia01'
                              target='_blank'>
                            <img
                                src={
                                    '/btnD_icon_circle.png'
                                }
                                style={logoStyle}
                                alt="logo of naver"
                            />
                        </Link>
                    </Box>
                </Stack>
            </Box>
        </Container>
    );
}
