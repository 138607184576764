import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {alpha, Backdrop, Fade, InputAdornment, Modal, Select, SelectChangeEvent, Typography} from '@mui/material';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import {gray} from "../getLPTheme";
import axios from "axios";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {costState, leaseFeeState, monthState, paymentState, rateState, resultState, valueState} from "../atom/state";

async function postData(
    cost: string,
    value: string,
    payment: string,
    month: string,
    leaseFee: string,
    setRateState: any,
    setResultState: any,
    handleOpen: any
) {
    let costNum = parseFloat(cost.replaceAll(",", ""));
    let costValue = parseFloat(value.replaceAll(",", ""));
    let costPayment = payment.length == 0 ? 0 : parseFloat(payment.replaceAll(",", ""));
    let costMonth = parseFloat(month.replaceAll(",", ""));
    let costLeaseFee = parseFloat(leaseFee.replaceAll(",", ""));

    console.log(costNum, costValue, costPayment, costMonth, costLeaseFee);

    try {
        const response: any = await axios.post('https://api.xn--2x3b45bh8bt5p.com/calc', {
                "cost": costNum,
                "value": costValue,
                "payment": costPayment,
                "month": costMonth,
                "leaseFee": costLeaseFee
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': 't5retlMRRl4zJm5Ez4YNOa2VawnzUPfF34ocZwky'
                }
            });
        setRateState(() => ({
            interest_rate: response.data.interest_rate,
            month_rate: response.data.month_rate,
            year_rate: response.data.year_rate,
            total_rate: response.data.total_rate,
            total_amount: response.data.total_amount
        }))
        setResultState(false);
    } catch (error: any) {
        if (error.response.status) {
            handleOpen();
        }
    }
}

export default function Calcurator() {
    const cost = useRecoilValue(costState);
    const value = useRecoilValue(valueState);
    const payment = useRecoilValue(paymentState);
    const month = useRecoilValue(monthState);
    const leaseFee = useRecoilValue(leaseFeeState);
    const setCost = useSetRecoilState(costState);
    const setValue = useSetRecoilState(valueState);
    const setPayment = useSetRecoilState(paymentState);
    const setMonth = useSetRecoilState(monthState);
    const setLeaseFee = useSetRecoilState(leaseFeeState);
    const setRateState = useSetRecoilState(rateState);
    const setResultState = useSetRecoilState(resultState);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const isFill = () => {
        return !(cost.length < 1 || value.length < 1 || month.length < 1 || leaseFee.length < 1);
    }

    const MonthHandleChange = (event: SelectChangeEvent) => {
        setMonth(event.target.value);
    };

    const CostOnChange = (e: any) => {
        let value = e.target.value.replace(/[^0-9]/g, '');
        value = Number(value);
        const formatValue = value.toLocaleString('ko-KR');
        setCost(formatValue);
    };

    const ValueOnChange = (e: any) => {
        let value = e.target.value.replace(/[^0-9]/g, '');
        value = Number(value);
        const formatValue = value.toLocaleString('ko-KR');
        setValue(formatValue);
    };

    const PaymentOnChange = (e: any) => {
        let value = e.target.value.replace(/[^0-9]/g, '');
        value = Number(value);
        const formatValue = value.toLocaleString('ko-KR');
        setPayment(formatValue);
    };

    const LeaseFeeOnChange = (e: any) => {
        let value = e.target.value.replace(/[^0-9]/g, '');
        value = Number(value);
        const formatValue = value.toLocaleString('ko-KR');
        setLeaseFee(formatValue);
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: '90%', sm: 500},
        bgcolor: 'background.paper',
        border: '2px solid #000',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4
    };

    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            sx={{
                                fontFamily: 'Pretendard',
                                fontWeight: '700',
                                fontSize: {xs: '20px', sm: '26px'},
                                letterSpacing: '-1px',
                                pl: 1
                            }}
                        >
                            입력값 오류
                        </Typography>
                        <Typography
                            id="transition-modal-description"
                            sx={{
                                mt: 2,
                                fontFamily: 'Pretendard',
                                fontWeight: '500',
                                fontSize: {xs: '16px', sm: '20px'},
                                letterSpacing: '-1px',
                                pl: 1
                            }}
                        >
                            이자 계산이 불가능한 입력값입니다.<br/>
                            각 항목의 값을 확인 후 입력해주세요. 😊
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Container id="features"
                       sx={{py: {xs: 8, sm: 16}, pt: {xs: 14, sm: 25}, px: {xs: 0, sm: 4}}}>
                <Card
                    sx={{

                        display: {xs: 'flex', sm: 'flex'},
                        padding: {xs: 2, sm: 0},
                        backgroundColor: {xs: 'transparent', sm: alpha(gray[800], 0.6)},
                        border: {xs: '0', sm: `1px solid ${alpha(gray[700], 0.3)}`},
                        justifyContent: "center",
                    }}
                >
                    <Grid container spacing={1} columns={{xs: 4, sm: 2, md: 12}}>
                        <Grid item xs={12} sm={2} md={6}>
                            <Stack useFlexGap sx={{width: {xs: '100%', sm: '380px'}, margin: 'auto'}}>
                                <Box sx={{pt: 4}}>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {xs: 'column', md: 'row'},
                                            alignSelf: 'center',
                                            textAlign: 'center',
                                            fontFamily: 'Pretendard',
                                            fontWeight: '700',
                                            fontSize: '32px',
                                            letterSpacing: '-1px'
                                        }}
                                    >
                                        리스 계산기
                                    </Typography>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {xs: 'column', md: 'row'},
                                            alignSelf: 'center',
                                            textAlign: 'left',
                                            fontFamily: 'Pretendard',
                                            fontWeight: '300',
                                            fontSize: '14px',
                                            letterSpacing: '-1px',
                                            color: '#B4B4B8',
                                            pt: 1,
                                            wordWrap: "break-word"
                                        }}
                                    >
                                        어려운 수학 계산 없이도, 단 몇 번의 클릭만으로 자신에게 맞는 최적의 자동차 리스 옵션을 찾을 수 있습니다.
                                        <br/>차량 가격, 기간, 연간 주행 마일리지, 이자율, 그리고 잔존 가치를 간편하게 입력하여 맞춤형 임대료를 확인하세요.
                                        <br/>비교적 저렴한 비용으로 새로운 자동차를 타고 당신의 일상을 더욱 편리하게 만들어보세요.
                                        <br/>자동차 리스 계산기를 사용하여 오늘부터 더 현명한 선택을 시작해보세요!"
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={2} md={6} sx={{justifyContent: 'center'}}>
                            <Stack useFlexGap sx={{width: {xs: '100%', sm: '380px'}, margin: 'auto'}}>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 4, width: {xs: '100%', sm: '380px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                            color: (theme) =>
                                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                                        }}
                                    >
                                        취득 원가
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: 'auto'}}}
                                >
                                    <TextField
                                        id="outlined-basicse"
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        sx={{width: {xs: '100%', sm: '380px'}}}
                                        inputProps={{
                                            style: {
                                                textAlign: "right",
                                                fontWeight: '400',
                                                fontSize: '17px',
                                                letterSpacing: '-1px'
                                            },

                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                                        }}
                                        onChange={CostOnChange}
                                        value={cost}
                                    />
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, width: {xs: '100%', sm: '280px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                            color: (theme) =>
                                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                                        }}
                                    >
                                        잔존 가치
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: 'auto'}}}
                                >
                                    <TextField
                                        id="outlined-basicse"
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        sx={{width: {xs: '100%', sm: '380px'}}}
                                        inputProps={{
                                            style: {
                                                textAlign: "right",
                                                fontWeight: '400',
                                                fontSize: '17px',
                                                letterSpacing: '-1px'
                                            },

                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                                        }}
                                        onChange={ValueOnChange}
                                        value={value}
                                    />
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, width: {xs: '100%', sm: '280px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                            color: (theme) =>
                                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                                        }}
                                    >
                                        선수금(보증금 제외)
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: 'auto'}}}
                                >
                                    <TextField
                                        id="outlined-basicse"
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        sx={{width: {xs: '100%', sm: '380px'}}}
                                        inputProps={{
                                            style: {
                                                textAlign: "right",
                                                fontWeight: '400',
                                                fontSize: '17px',
                                                letterSpacing: '-1px'
                                            },

                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                                        }}
                                        onChange={PaymentOnChange}
                                        value={payment}
                                    />
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, pb: 1, width: {xs: '100%', sm: '280px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                            color: (theme) =>
                                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                                        }}
                                    >
                                        잔여 개월 수
                                    </Typography>
                                </Stack>
                                <Select
                                    id="demo-simple-select"
                                    value={month}
                                    onChange={MonthHandleChange}
                                    sx={{
                                        borderRadius: '10px',
                                        height: '40px',
                                        fontFamily: 'Pretendard',
                                        fontWeight: '600',
                                        fontSize: '15px',
                                        letterSpacing: '-1px',
                                    }}
                                >
                                    <MenuItem value={"12"}>12개월</MenuItem>
                                    <MenuItem value={"24"}>24개월</MenuItem>
                                    <MenuItem value={"36"}>36개월</MenuItem>
                                    <MenuItem value={"48"}>48개월</MenuItem>
                                    <MenuItem value={"60"}>60개월</MenuItem>
                                </Select>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, width: {xs: '100%', sm: '280px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                            color: (theme) =>
                                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                                        }}
                                    >
                                        월 리스료
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: 'auto'}}}
                                >
                                    <TextField
                                        id="outlined-basicse"
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        sx={{width: {xs: '100%', sm: '380px'}}}
                                        inputProps={{
                                            style: {
                                                fontFamily: 'Pretendard',
                                                textAlign: "right",
                                                fontWeight: '400',
                                                fontSize: '17px',
                                                letterSpacing: '-1px'
                                            },

                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                                        }}
                                        onChange={LeaseFeeOnChange}
                                        value={leaseFee}
                                    />
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2}}
                                >
                                    {
                                        isFill() ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => postData(cost, value, payment, month, leaseFee, setRateState, setResultState, handleOpen)}
                                                sx={{
                                                    width: {xs: '100%', sm: '380px'},
                                                    fontFamily: 'Pretendard',
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                    letterSpacing: '-1px',
                                                }}>
                                                견적 계산하기
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled
                                                sx={{
                                                    width: {xs: '100%', sm: '380px'},
                                                    fontFamily: 'Pretendard',
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                    letterSpacing: '-1px',
                                                }}>
                                                견적 계산하기
                                            </Button>
                                        )
                                    }
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, mb: 4}}
                                >
                                    <Button
                                        fullWidth
                                        color="info"
                                        variant="contained"
                                        href="https://cafe.naver.com/maffia01"
                                        target="_blank"
                                        sx={{
                                            width: {xs: '100%', sm: '380px'},
                                            fontFamily: 'Pretendard',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                            letterSpacing: '-1px',
                                        }}>
                                        네이버 카페 바로가기
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </Box>
    );
}
