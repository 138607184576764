import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Link from "@mui/material/Link";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

function AppAppBar() {

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const scrollToSection = (sectionId: string) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({behavior: 'smooth'});
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
            setOpen(false);
        }
    };

    const toolbar = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 0,
        borderRadius: '999px',
        bgcolor: 'rgba(0, 0, 0, 0.4)',
        backdropFilter: 'blur(24px)',
        maxHeight: 40,
        border: '1px solid',
        borderColor: 'divider',
        boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)'
    };

    const appBar = {
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 2,
    }

    const boxStyle = {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        ml: '-18px',
        px: 0,
    }

    const logoStyle = {
        fontFamily: 'Pretendard',
        letterSpacing: '2px',
        display: 'flex',
        flexDirection: {xs: 'column', md: 'row'},
        alignSelf: 'center',
        textAlign: 'center',
        fontWeight: '800',
        fontSize: '20px',
        ml: 2,
        mr: 1,
        color: 'primary.main',
    };

    const itemStyle = {
        fontFamily: 'Pretendard',
        fontWeight: '400',
        fontSize: '16px',
        letterSpacing: '-1px'
    };

    const cafeStyle = {
        color: '#5BB318',
        fontFamily: 'Pretendard',
        fontWeight: '400',
        fontSize: '16px',
        letterSpacing: '-1px'
    }

    const menuStyle = {
        py: '6px',
        px: '12px'
    };

    const drawerStyle = {
        minWidth: '60dvw',
        pt: 6,
        pl: 2,
        backgroundColor: 'background.paper',
        flexGrow: 1,
    }

    return (
        <div>
            <AppBar position="fixed" sx={appBar}>
                <Container maxWidth="lg">
                    <Toolbar variant="regular" sx={toolbar}>
                        <Box sx={boxStyle}>
                            <Link href="/" underline="none">
                                <Typography sx={logoStyle}>
                                    신차빠삭
                                </Typography>
                            </Link>
                            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                                <Link href="/" underline="none">
                                    <MenuItem onClick={() => scrollToSection('features')} sx={menuStyle}>
                                        <Typography color="text.primary" sx={itemStyle}>
                                            리스 계산기
                                        </Typography>
                                    </MenuItem>
                                </Link>
                                <Link href="/consult" underline="none">
                                    <MenuItem sx={menuStyle}>
                                        <Typography color="text.primary" sx={itemStyle}>
                                            견적서 상담
                                        </Typography>
                                    </MenuItem>
                                </Link>
                                <Link href="https://cafe.naver.com/maffia01" target='_blank' underline="none">
                                    <MenuItem onClick={() => scrollToSection('faq')} sx={menuStyle}>
                                        <Typography sx={cafeStyle}>
                                            네이버 카페
                                            <ArrowOutwardIcon sx={{fontSize: 14, color: '#5BB318'}}/>
                                        </Typography>
                                    </MenuItem>
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{display: {sm: '', md: 'none'}}}>
                            <Button
                                variant="text"
                                color="primary"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{minWidth: '30px', p: '4px'}}
                            >
                                <MenuIcon/>
                            </Button>
                            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                                <Box sx={drawerStyle}>
                                    <Link href="/" underline="none">
                                        <MenuItem>
                                            리스 계산기
                                        </MenuItem>
                                    </Link>
                                    <Link href="/consult" underline="none">
                                        <MenuItem>
                                            견적서 상담
                                        </MenuItem>
                                    </Link>
                                    <Link href="https://cafe.naver.com/maffia01" target='_blank' underline="none">
                                        <MenuItem>
                                            <Typography sx={{color: '#5BB318'}}>
                                                네이버 카페
                                            </Typography>
                                            <ArrowOutwardIcon sx={{fontSize: 14, color: '#5BB318'}}/>
                                        </MenuItem>
                                    </Link>
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}

export default AppAppBar;
