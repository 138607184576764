import {atom} from 'recoil';

export type RateStateType = {
    interest_rate: string;
    month_rate: string;
    year_rate: string;
    total_rate: string;
    total_amount: string;
}

export const resultState = atom<boolean>({
    key: 'result',
    default: true
});

export const costState = atom<string>({
    key: 'costState',
    default: ""
});

export const valueState = atom<string>({
    key: 'valueState',
    default: ""
});

export const paymentState = atom<string>({
    key: 'paymentState',
    default: ""
});

export const monthState = atom<string>({
    key: 'monthState',
    default: "12"
});

export const leaseFeeState = atom<string>({
    key: 'leaseFeeState',
    default: ""
});

export const rateState = atom<RateStateType>({
    key: 'rateState',
    default: {
        interest_rate: "",
        month_rate: "",
        year_rate: "",
        total_rate: "",
        total_amount: ""
    }
});

export const nameState = atom<string>({
    key: 'nameState',
    default: ""
});

export const phoneState = atom<string>({
    key: 'phoneState',
    default: ""
});

export const contactWayState = atom<string>({
    key: 'contactWayState',
    default: "전화"
});

export const requestState = atom<string>({
    key: 'requestState',
    default: ""
});

export const reqBtnState = atom<boolean>({
    key: 'reqBtnState',
    default: false
})



