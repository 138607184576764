import * as React from 'react';
import {useRef} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {alpha} from "@mui/material";
import {useRecoilValue} from "recoil";
import {rateState} from "../atom/state";
import html2canvas from "html2canvas";
import saveAs from "file-saver";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DvrIcon from '@mui/icons-material/Dvr';

export default function CalcResult() {

    const rate = useRecoilValue(rateState);
    const divRef = useRef<HTMLDivElement>(null);

    const handleDownload = async () => {
        if (!divRef.current) return;

        try {
            const div = divRef.current;
            const canvas = await html2canvas(div, {scale: 2});
            canvas.toBlob((blob) => {
                if (blob !== null) {
                    saveAs(blob, "lease-calc-result.png");
                }
            });
        } catch (error) {
            console.error("Error converting div to image:", error);
        }
    };

    const boxStyle = {
        width: '100%',
        backgroundImage: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
    }

    const containerStyle = {
        pt: {xs: 12, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
        mb: 10,
        px: {xs: 0, sm: 4}
    }

    const firstBoxStyle = {
        padding: {xs: 2, sm: 0},
        mt: 4,
        width: {xs: '100%', sm: '100%', md: '60%'},
        textAlign: {xs: 'left', sm: 'left', md: 'center'},
    }

    const leaseText = {
        fontSize: '36px',
        fontWeight: 700,
        fontFamily: 'Pretendard',
    }

    const leaseExplainText = {
        mt: 1,
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Pretendard',
    }


    return (
        <Box sx={boxStyle}>
            <Container sx={containerStyle}>
                <Box sx={firstBoxStyle}>
                    <Typography sx={leaseText}>
                        적정 이자율이 궁금하세요?
                    </Typography>
                    <Typography sx={leaseExplainText} color="text.secondary">
                        신차 빠삭은 자동차 리스 계획에 관한 모든 것을 상담해드립니다.
                        <br/>계산 결과에 대한 해석부터 최적의 리스 옵션을 선택하는 데 필요한 조언까지,
                        <br/>전문 상담팀이 최선을 다해 도와드릴 것입니다.
                        <br/>여러분의 요구와 예산에 맞는 자동차 리스를 찾는 데 도움을 드리고,
                        <br/>만족할 수 있는 최상의 거래를 약속 드립니다.
                    </Typography>
                </Box>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid
                        ref={divRef}
                        sx={{mt: 3, backgroundColor: "#090E10"}}
                        item
                        xs={12}
                        md={8}
                    >
                        <Card
                            sx={{
                                p: 1,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography sx={{
                                            fontSize: '24px',
                                            fontWeight: 800,
                                            fontFamily: 'Pretendard',
                                            color: '#55A6F6',
                                            letterSpacing: '2px'
                                        }}>
                                            신차빠삭&nbsp;
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '24px',
                                            fontWeight: 800,
                                            fontFamily: 'Pretendard'
                                        }}>
                                            리스 견적 분석 결과
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider
                                    sx={{
                                        my: 2,
                                        opacity: 0.2,
                                        borderColor: 'grey.500',
                                    }}
                                />
                                <Box
                                    sx={{
                                        mt: 1,
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: '18px',
                                        fontWeight: 700,
                                        fontFamily: 'Pretendard',
                                        mb: 1
                                    }}>
                                        이자율
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '36px',
                                        fontWeight: 700,
                                        fontFamily: 'Pretendard',
                                    }}>
                                        {rate.interest_rate}%
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        mt: 2,
                                        py: 1,
                                        display: 'flex',
                                        gap: 1.5,
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1.5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CheckCircleRoundedIcon
                                            sx={{
                                                width: 20,
                                                color: 'primary.main',
                                            }}
                                        />
                                        <Typography sx={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            fontFamily: 'Pretendard',
                                        }}>
                                            총 이자
                                        </Typography>
                                    </Box>
                                    <Typography sx={{
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        fontFamily: 'Pretendard',
                                    }}>
                                        {rate.total_rate}원
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1.5,
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1.5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CheckCircleRoundedIcon
                                            sx={{
                                                width: 20,
                                                color: 'primary.main',
                                            }}
                                        />
                                        <Typography sx={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            fontFamily: 'Pretendard',
                                        }}>
                                            연 이자
                                        </Typography>
                                    </Box>
                                    <Typography sx={{
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        fontFamily: 'Pretendard',
                                    }}>
                                        {rate.year_rate}원
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        py: 1,
                                        display: 'flex',
                                        gap: 1.5,
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1.5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CheckCircleRoundedIcon
                                            sx={{
                                                width: 20,
                                                color: 'primary.main',
                                            }}
                                        />
                                        <Typography sx={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            fontFamily: 'Pretendard',
                                        }}>
                                            월 이자
                                        </Typography>
                                    </Box>
                                    <Typography sx={{
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        fontFamily: 'Pretendard',
                                    }}>
                                        {rate.month_rate}원
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1.5,
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1.5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CheckCircleRoundedIcon
                                            sx={{
                                                width: 20,
                                                color: 'primary.main',
                                            }}
                                        />
                                        <Typography sx={{
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            fontFamily: 'Pretendard',
                                        }}>
                                            총 이용금액
                                        </Typography>
                                    </Box>
                                    <Typography sx={{
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        fontFamily: 'Pretendard',
                                    }}>
                                        {rate.total_amount}원
                                    </Typography>
                                </Box>
                            </CardContent>
                            <CardActions>
                                <Button
                                    fullWidth
                                    variant={'contained'}
                                    onClick={handleDownload}
                                    startIcon={<CloudDownloadIcon/>}
                                >
                                    분석 결과 저장하기
                                </Button>
                            </CardActions>
                            <CardActions>
                                <Button
                                    fullWidth
                                    variant={'contained'}
                                    href="/consult"
                                    startIcon={<DvrIcon/>}
                                >
                                    상담 요청하기
                                </Button>
                            </CardActions>
                            <CardActions>
                                <Button
                                    fullWidth
                                    color="info"
                                    variant={'contained'}
                                    href="https://cafe.naver.com/maffia01"
                                    target="_blank"
                                >
                                    네이버 카페 바로가기
                                </Button>
                            </CardActions>
                            <CardActions>
                                <Button
                                    fullWidth
                                    variant={'outlined'}
                                    href="/"
                                >
                                    돌아가기
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
