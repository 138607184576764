import Divider from "@mui/material/Divider";
import Footer from "../components/Footer";
import * as React from "react";
import Consulting from "../components/Consulting";

export default function Consult() {


    return (
        <div>
            <Consulting/>
            <Divider/>
            <Footer/>
        </div>
    );
}
