import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
    alpha,
    Backdrop,
    Checkbox,
    CircularProgress,
    Fade,
    FormControlLabel,
    Input,
    Modal,
    Select,
    SelectChangeEvent
} from "@mui/material";
import {gray} from "../getLPTheme";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import axios from "axios";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {contactWayState, nameState, phoneState, reqBtnState, requestState} from "../atom/state";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

async function upload(
    files: any,
    name: string,
    phone: string,
    contactWay: string,
    request: string,
    setReqBtn: any,
    handleOpen: any,
    handleErrorOpen: any
) {
    if (name.length < 1 || phone.length < 1 || request.length < 1) {
        handleOpen();
    } else {
        setReqBtn(true);
        if (files == undefined) {
            postData(name, phone, contactWay, request, '없음', handleErrorOpen);
        } else {
            try {
                console.log(files[0])
                const formData = new FormData()
                formData.append("file", files[0]) //files[0] === upload file

                const response: any = await axios.post('https://api.xn--2x3b45bh8bt5p.com/file', formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'X-Api-Key': 'XKZL0RQ5mt5K9qkPwdGTx1jquope6Ogt1fbGTtGa'
                        }
                    });
                postData(name, phone, contactWay, request, response.data.url[0], handleErrorOpen);
            } catch (error: any) {
                if (error.response.status) {
                    handleErrorOpen();
                }
            }
        }
    }
}

async function postData(
    name: string,
    phone: string,
    contactWay: string,
    request: string,
    url: any,
    handleErrorOpen: any
) {

    try {
        const response: any = await axios.put('https://api.xn--2x3b45bh8bt5p.com/contact', {
                "name": name,
                "phone": phone,
                "request": request,
                "contact_way": contactWay,
                "url": url
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': 't5retlMRRl4zJm5Ez4YNOa2VawnzUPfF34ocZwky'
                }
            });
        window.location.href = '/result';
    } catch (error: any) {
        if (error.response.status) {
            handleErrorOpen();
        }
    }
}

export default function Consulting() {
    const clause: string = "(주)신차빠삭이 수집·이용하는 이용자의 개인정보에 대하여 아래와 같이 관계 법령에 따라 알려드리오니, 동의해주시기 바랍니다.\n" +
        "            \n" +
        "[수집하는 개인정보 항목]\n" +
        "1) 수집항목 : 성명, 연락처, 이메일 등\n" +
        "2) 수집방법 : 상담 요청서 취합\n" +
        "\n" +
        "[수집 이용 목적]\n" +
        "견적서 상담을 위한 본인 확인 및 상담 연락 등으로 이용되며, 수집한 개인정보는 본 수집•이용 목적 외의 다른 목적으로 사용되지 않습니다.\n" +
        "\n" +
        "[이용 및 보유 기간]\n" +
        "수집된 개인정보는 상담 종료 후 7일 이내 즉시 파기합니다.\n" +
        "\n" +
        "[개인정보의 제3자 제공 및 위탁]\n" +
        "(주)신차빠삭은 원칙적으로 이용자의 동의 없이 해당 개인정보의 처리를 제3자에게 위탁하지 않습니다.\n" +
        "\n" +
        "[동의거부 권리 및 불이익]\n" +
        "정보주체는 개인정보 수집에 동의를 거부할 수 있으며, 필수 작성 사항을 기재하지 않을 시 견적서 상담 요청이 불가함을 알려드립니다.\n" +
        "본인은 위 내용을 확인하고 이에 전부 동의하며, 견적서 상담 요청을 신청하고자 합니다.";
    const name = useRecoilValue(nameState);
    const phone = useRecoilValue(phoneState);
    const contactWay = useRecoilValue(contactWayState);
    const request = useRecoilValue(requestState);
    const setName = useSetRecoilState(nameState);
    const setPhone = useSetRecoilState(phoneState);
    const setContactWay = useSetRecoilState(contactWayState);
    const setRequest = useSetRecoilState(requestState);
    const reqBtn = useRecoilValue(reqBtnState);
    const setReqBtn = useSetRecoilState(reqBtnState);
    const [files, setFiles] = useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const handleErrorOpen = () => setErrorOpen(true);
    const handleErrorClose = () => setErrorOpen(false);
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleFilesChange = (e: any) => {
        setFiles(e.target.files);
    }

    const ContactWayHandleChange = (event: SelectChangeEvent) => {
        setContactWay(event.target.value);
    };

    const NameOnChange = (e: any) => {
        setName(e.target.value);
    };

    const PhoneOnChange = (e: any) => {
        setPhone(e.target.value);
    };

    const RequestOnChange = (e: any) => {
        setRequest(e.target.value);
    };
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: '90%', sm: 500},
        bgcolor: 'background.paper',
        border: '2px solid #000',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4
    };

    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            sx={{
                                fontFamily: 'Pretendard',
                                fontWeight: '700',
                                fontSize: {xs: '20px', sm: '26px'},
                                letterSpacing: '-1px',
                                pl: 1
                            }}
                        >
                            입력값 오류
                        </Typography>
                        <Typography
                            id="transition-modal-description"
                            sx={{
                                mt: 2,
                                fontFamily: 'Pretendard',
                                fontWeight: '500',
                                fontSize: {xs: '16px', sm: '20px'},
                                letterSpacing: '-1px',
                                pl: 1
                            }}
                        >
                            성함, 연락처, 상담 요청사항을 모두 입력해주세요. 😊
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                open={errorOpen}
                onClose={handleErrorClose}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={errorOpen}>
                    <Box sx={style}>
                        <Typography
                            id="transition-modal-title"
                            sx={{
                                fontFamily: 'Pretendard',
                                fontWeight: '700',
                                fontSize: {xs: '20px', sm: '26px'},
                                letterSpacing: '-1px',
                                pl: 1
                            }}
                        >
                            입력값 오류
                        </Typography>
                        <Typography
                            id="transition-modal-description"
                            sx={{
                                mt: 2,
                                fontFamily: 'Pretendard',
                                fontWeight: '500',
                                fontSize: {xs: '16px', sm: '20px'},
                                letterSpacing: '-1px',
                                pl: 1
                            }}
                        >
                            입력하신 항목을 확인해주세요. 😊
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Container id="features"
                       sx={{py: {xs: 8, sm: 16}, pt: {xs: 14, sm: 25}, px: {xs: 0, sm: 4}}}>
                <Card
                    sx={{
                        display: {xs: 'flex', sm: 'flex'},
                        padding: {xs: 2, sm: 0},
                        backgroundColor: {xs: 'transparent', sm: alpha(gray[800], 0.6)},
                        border: {xs: '0', sm: `1px solid ${alpha(gray[700], 0.3)}`},
                        margin: "auto",
                        justifyContent: "center"
                    }}
                >
                    <Grid container spacing={1} columns={{xs: 4, sm: 2, md: 12}}>
                        <Grid item xs={12} sm={2} md={6}>
                            <Stack useFlexGap sx={{width: {xs: '100%', sm: '380px'}, margin: 'auto'}}>
                                <Box sx={{pt: 4}}>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {xs: 'column', md: 'row'},
                                            alignSelf: 'center',
                                            textAlign: 'center',
                                            fontFamily: 'Pretendard',
                                            fontWeight: '700',
                                            fontSize: '32px',
                                            letterSpacing: '-1px'
                                        }}
                                    >
                                        적정 이자율이 궁금하세요?
                                    </Typography>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {xs: 'column', md: 'row'},
                                            alignSelf: 'center',
                                            textAlign: {xs: 'center', sm: 'center', md: 'left'},
                                            fontFamily: 'Pretendard',
                                            fontWeight: '300',
                                            fontSize: '14px',
                                            letterSpacing: '-1px',
                                            color: '#B4B4B8',
                                            pt: 1,
                                            wordWrap: "break-word"
                                        }}
                                    >
                                        신차 빠삭은 자동차 리스 계획에 관한 모든 것을 상담해드립니다.
                                        <br/>계산 결과에 대한 해석부터 최적의 리스 옵션을 선택하는 데 필요한 조언까지,
                                        <br/>전문 상담팀이 최선을 다해 도와드릴 것입니다.
                                        <br/>여러분의 요구와 예산에 맞는 자동차 리스를 찾는 데 도움을 드리고,
                                        <br/>만족할 수 있는 최상의 거래를 약속 드립니다.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={2} md={6} sx={{justifyContent: 'center'}}>
                            <Stack useFlexGap sx={{width: {xs: '100%', sm: '380px'}, margin: 'auto'}}>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 4, width: {xs: '100%', sm: '380px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        color="primary"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                        }}
                                    >
                                        성함 *
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: 'auto'}}}
                                >
                                    <TextField
                                        id="outlined-basicse"
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        sx={{width: {xs: '100%', sm: '380px'}}}
                                        inputProps={{
                                            style: {
                                                fontWeight: '400',
                                                fontSize: '17px',
                                                letterSpacing: '-1px'
                                            }
                                        }}
                                        onChange={NameOnChange}
                                        value={name}
                                    />
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, width: {xs: '100%', sm: '280px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        color="primary"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                        }}
                                    >
                                        연락처 *
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: 'auto'}}}
                                >
                                    <TextField
                                        id="outlined-basicse"
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        sx={{width: {xs: '100%', sm: '380px'}}}
                                        inputProps={{
                                            style: {
                                                fontWeight: '400',
                                                fontSize: '17px',
                                                letterSpacing: '-1px'
                                            }
                                        }}
                                        onChange={PhoneOnChange}
                                        value={phone}
                                    />
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, pb: 1, width: {xs: '100%', sm: '280px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        color="primary"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                        }}
                                    >
                                        연락 방법 *
                                    </Typography>
                                </Stack>
                                <Select
                                    value={contactWay}
                                    onChange={ContactWayHandleChange}
                                    sx={{
                                        borderRadius: '10px',
                                        height: '40px',
                                        fontFamily: 'Pretendard',
                                        fontWeight: '600',
                                        fontSize: '15px',
                                        letterSpacing: '-1px',
                                    }}
                                >
                                    <MenuItem value={'전화'}>전화주세요</MenuItem>
                                    <MenuItem value={'문자'}>문자주세요</MenuItem>
                                </Select>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, width: {xs: '100%', sm: '280px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        color="primary"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                        }}
                                    >
                                        상담 요청 사항 *
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: 'auto'}}}
                                >
                                    <TextField
                                        id="outlined-basicse"
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        sx={{width: {xs: '100%', sm: '380px'}}}
                                        inputProps={{
                                            style: {
                                                fontFamily: 'Pretendard',
                                                fontWeight: '400',
                                                fontSize: '17px',
                                                letterSpacing: '-1px'
                                            }
                                        }}
                                        onChange={RequestOnChange}
                                        value={request}
                                    />
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, width: {xs: '100%', sm: '280px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        color="primary"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                        }}
                                    >
                                        견적서 첨부 (최대 10Mb)
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: '380px'}}}
                                >
                                    {
                                        files == undefined ? (
                                            <Button
                                                variant="contained"
                                                component="label"
                                                sx={{pt: 1, width: {xs: '100%', sm: '380px'}}}
                                                startIcon={<CloudUploadIcon/>}

                                            >
                                                Upload File
                                                <Input type="file" sx={{
                                                    clip: 'rect(0 0 0 0)',
                                                    clipPath: 'inset(50%)',
                                                    height: 1,
                                                    overflow: 'hidden',
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    whiteSpace: 'nowrap',
                                                    width: '380px',
                                                }}
                                                       onChange={handleFilesChange}
                                                />
                                            </Button>
                                        ) : (
                                            <Button
                                                component="label"
                                                variant="contained"
                                                tabIndex={-1}
                                            >
                                                첨부 완료
                                                <Input type="file" sx={{
                                                    clip: 'rect(0 0 0 0)',
                                                    clipPath: 'inset(50%)',
                                                    height: 1,
                                                    overflow: 'hidden',
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    whiteSpace: 'nowrap',
                                                    width: '380px',

                                                }}
                                                       onChange={handleFilesChange}
                                                />
                                            </Button>
                                        )
                                    }
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 2, width: {xs: '100%', sm: '280px'}, textAlign: 'left'}}
                                >
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        color="primary"
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                        }}
                                    >
                                        개인정보 수집 및 이용 약관
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: 'auto'}}}
                                >
                                    <TextField
                                        id="outlined-basicse"
                                        variant="outlined"
                                        sx={{
                                            width: {xs: '100%', sm: '380px'},

                                        }}
                                        multiline
                                        maxRows={4}
                                        inputProps={{
                                            style: {
                                                fontFamily: 'Pretendard',
                                                fontWeight: '300',
                                                fontSize: '15px',
                                                letterSpacing: '-1px',
                                            }
                                        }}
                                        value={clause}
                                    />
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="left"
                                    useFlexGap
                                    sx={{pt: 1, width: {xs: '100%', sm: 'auto'}}}
                                >
                                    <FormControlLabel
                                        required
                                        control={<Checkbox onChange={handleChange}/>}
                                        label="개인정보 수집 및 이용 약관에 동의합니다."
                                        sx={{
                                            fontFamily: 'Pretendard',
                                            fontWeight: '800',
                                            fontSize: '17px',
                                            letterSpacing: '-1px',
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    alignSelf="center"
                                    spacing={1}
                                    useFlexGap
                                    sx={{pt: 1, mb: 4}}
                                >
                                    {
                                        reqBtn ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled
                                                sx={{
                                                    width: {xs: '100%', sm: '380px'},
                                                    fontFamily: 'Pretendard',
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                    letterSpacing: '-1px',
                                                }}>
                                                <CircularProgress color="primary" size="1rem" sx={{mr: 2}}/>
                                                상담 요청하기
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={!checked}
                                                onClick={() => upload(files, name, phone, contactWay, request, setReqBtn, handleOpen, handleErrorOpen)}
                                                sx={{
                                                    width: {xs: '100%', sm: '380px'},
                                                    fontFamily: 'Pretendard',
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                    letterSpacing: '-1px',
                                                }}>
                                                상담 요청하기
                                            </Button>
                                        )
                                    }
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </Box>
    );
}
