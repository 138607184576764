import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {alpha} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Success() {

    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    pt: {xs: 20, sm: 20, md: 20},
                    pb: {xs: 8, sm: 16},
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: {xs: 3, sm: 6},
                    mb: 10
                }}
            >
                <Box
                    sx={{
                        mt: 20,
                        width: {xs: '100%', sm: '100%', md: '60%'},
                    }}
                >
                    <CheckCircleIcon
                        sx={{color: '#74E291', fontSize: {xs: '70px', sm: '70px', md: '140px'}}}
                    />
                    <Typography sx={{
                        fontSize: {xs: '20px', sm: '20px', md: '36px'},
                        fontWeight: 700,
                        fontFamily: 'Pretendard',
                    }}>
                        상담 요청이 완료됐습니다!
                    </Typography>
                    <Typography sx={{
                        mt: 1,
                        fontSize: '14px',
                        fontWeight: 400,
                        fontFamily: 'Pretendard',
                    }} color="text.secondary">
                        전문 상담팀에게 상담 요청 내용이 전달됐습니다.
                        <br/>곧 연락 드리겠습니다.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
