import * as React from 'react';
import Divider from '@mui/material/Divider';
import Calcurator from '../components/Calcurator';
import Footer from '../components/Footer';
import CalcResult from "../components/CalcResult";
import {useRecoilValue} from "recoil";
import {resultState} from "../atom/state";

export default function LandingPage() {

    const result = useRecoilValue(resultState);
    return (
        <div>
            {
                result ? <Calcurator/> : <CalcResult/>
            }
            <Divider/>
            <Footer/>
        </div>
    );
}
