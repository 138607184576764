import React from 'react';
import './App.css';
import LandingPage from "./routes/LandingPage";
import Result from "./routes/Result";
import Consult from "./routes/Consult";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import getLPTheme from "./getLPTheme";
import AppAppBar from "./components/AppAppBar";
import {RecoilRoot} from "recoil";

function App() {
    const theme = createTheme(getLPTheme('dark'));

    const router = createBrowserRouter([
        {
            path: "/",
            element: <LandingPage/>,
            errorElement: <LandingPage/>,
        },
        {
            path: "/result",
            element: <Result/>,
        },
        {
            path: "/result/:result",
            element: <Result/>,
        },
        {
            path: "/consult",
            element: <Consult/>,
        },
        {
            path: "/",
            element: <LandingPage/>,
        },
    ]);

    return (
        <div className="App">
            <RecoilRoot>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <AppAppBar/>
                    <RouterProvider router={router}/>
                </ThemeProvider>
            </RecoilRoot>
        </div>

    );
}

export default App;
